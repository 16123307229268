<template>
    <UiMultiColumnsLayoutNew :blok="blok" />
</template>

<script setup lang="ts">
import type { IMultiColumnsLayout } from '~ui/types/components/MultiColumnsLayout';

defineProps<{ blok: IMultiColumnsLayout }>();
</script>

<style scoped lang="postcss">
:deep(.container) {
    @apply mx-auto px-4;
}

.multi-column-layout {
    @apply bg-transparent max-md:!bg-center;
    :deep(&.hide-on-mobile) {
        @apply hidden md:block;
    }
    :deep(&.hide-on-desktop) {
        @apply block md:hidden;
    }

    :deep(.slot) {
        @apply max-md:col-auto !important;
        .button {
            @apply w-[136px] flex justify-center mt-auto;
        }
        .simple-image {
            @apply min-h-[var(--min-image-wrapper-height)] flex;
            .wrapper {
                @apply self-end;
                &.mob-hidden {
                    @apply hidden md:flex;
                }

                img {
                    @apply self-end;
                }
            }
        }
    }
    :deep(.grid-wrapper) {
        --multicolumn-row-gap: 24px;
        --column-gap: 24px;
        @apply gap-x-[var(--column-gap)] gap-y-[var(--multicolumn-row-gap)];
        @media screen(md) {
            --multicolumn-row-gap: 33px;
            --column-gap: 44px;
        }
    }

    :deep(.section-subtitle) {
        @media screen and (max-width: 768px) {
            @apply text-center !important;
        }
        @apply block text-base leading-[18px] font-normal text-body mt-0 mb-6 mx-0;
    }

    :deep(#UiGeneralText) {
        .rich-text a:hover {
            @apply brightness-[90%];
        }

        .rich-text > *:first-child {
            @apply mt-0 leading-[normal];
        }
    }
}
</style>
