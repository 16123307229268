<template>
    <nuxt-link
        class="button"
        :class="[blok.Style, { 'hidden-mobile': blok.HiddenMobile, 'full-width': blok.fullWidth }]"
        :style="buttonStyles"
        :to="url"
        external
        @click="onClick"
    >
        <NuxtIcon v-if="blok.Icon?.icon" :name="`ri:${blok.Icon}`" />
        <span>{{ blok.Text }}</span>
    </nuxt-link>
</template>

<script setup lang="ts">
import { hexToRgba } from '~ui/utils/hexToRGBA';
import { useEventBus } from '@vueuse/core';

const { blok } = defineProps<{ blok: any }>();
const url = blok.Link.email ? `mailto:${blok.Link.email}` : getInternalLink(blok.Link);

const boxShadowColor = computed(() => hexToRgba(blok.BorderColor.color, 0.5));

const eventBus = useEventBus(blok.triggerEvent);
const buttonStyles = computed(() => {
    return {
        '--hover-color': blok.HoverColor?.color || null,
        '--hover-color-text': blok.HoverColorText?.color || null,
        '--box-shadow': `0 0 0 0.2rem ${boxShadowColor}`,
        '--border-color': blok.BorderColor?.color || null,
        '--align-self': blok.selfAlign || null,
        '--icon-position': blok.iconPosition || null,
        'background-color': blok.BackgroundColor?.color || null,
        'font-size': blok.FontSize ? `${blok.FontSize}px` : null,
        'padding-left': blok.PaddingX || null,
        'padding-right': blok.PaddingX || null,
        'padding-top': blok.PaddingY || null,
        'padding-bottom': blok.PaddingY || null,
        'border-width': blok.BorderWidth ? `${blok.BorderWidth}px` : '1px',
        'border-color': blok.BorderColor?.color || null,
        ...(blok.FontWeight ? { 'font-weight': blok.FontWeight } : {}),
        color: blok.TextColor?.color || null,
    };
});

function onClick(e: any) {
    if (blok.triggerEvent) {
        e.preventDefault();
        eventBus.on(() => {});
        return false;
    }
}
</script>

<style scoped lang="postcss">
.button {
    @apply flex items-center w-fit h-fit rounded box-border border text-[white] bg-[#f98f43] cursor-pointer text-xl px-16 py-3 border-solid border-[#f98f43] gap-2.5;
    align-self: var(--align-self, center);
    flex-direction: var(--icon-position, row);
    transition:
        color 0.15s ease-in-out,
        background-color 0.15s ease-in-out,
        border-color 0.15s ease-in-out,
        box-shadow 0.15s ease-in-out;
    &.hidden-mobile {
        @apply md:flex hidden;
    }
    &.full-width {
        @apply w-full justify-center;
    }

    &.general {
        @apply bg-[#f98f43] text-white font-semibold border-[#f98f43] !important;

        &:hover {
            @apply text-white bg-[#f8791e] border-[#f8791e] !important;
        }

        &:focus {
            @apply shadow-[0_0_0_0.2rem_rgba(249,143,67,0.5)];
        }
    }

    &:hover {
        @apply text-[color:var(--hover-color-text)] border-[color:var(--hover-color)] bg-[color:var(--hover-color)] !important;
    }

    &:focus {
        box-shadow: var(--box-shadow);
    }

    .iconify {
        @apply mr-2.5;
    }

    &.sps-link {
        border-left: none !important;
        border-bottom: none !important;
        border-right: none !important;
        border-radius: 0;
        @apply border-[color:var(--border-color)] !important;
        @apply p-0 w-max;
    }

    &.transparent-bg {
        @apply !border-0 !bg-transparent;
    }
}
</style>
