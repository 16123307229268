<template>
    <div class="ui-simple-rich-text" data-test="ui-simple-rich-text" :style="componentStyles">
        <div class="rich-text" :class="`text-${blok.textAlignment}`">
            <rich-text :content="blok.richText" />
        </div>
    </div>
</template>

<script setup lang="ts">
import type { VNode } from 'vue';
import { createTextVNode, h } from 'vue';
import { StoryblokComponent } from '@storyblok/vue';
import {
    BlockTypes,
    richTextResolver,
    type StoryblokRichTextNode,
    type StoryblokRichTextOptions,
} from '@storyblok/richtext';

import type { ICoreSimpleRichText } from '~ui/types/components/CoreSimpleRichText';

const { blok } = defineProps<{ blok: ICoreSimpleRichText }>();
const { render } = useStoryblokRichText({});
const componentStyles = computed(() => ({
    'padding-top': blok.paddingTop ? `${blok.paddingTop}px` : null,
    'padding-right': blok.paddingRight ? `${blok.paddingRight}px` : null,
    'padding-bottom': blok.paddingBottom ? `${blok.paddingBottom}px` : null,
    'padding-left': blok.paddingLeft ? `${blok.paddingLeft}px` : null,
    '--line-height': blok.lineHeight ? `${blok.lineHeight}px` : null,
}));

const options: StoryblokRichTextOptions<VNode> = {
    renderFn: h,
    textFn: createTextVNode,
    resolvers: {
        [BlockTypes.COMPONENT]: (node: StoryblokRichTextNode<VNode>) => {
            return node?.attrs?.body.map((blok) => {
                return h(StoryblokComponent, {
                    blok: blok,
                });
            });
        },
    },
};

const richText = (content: any) => {
    return richTextResolver<VNode>(options).render(content.content);
};

// Hides OneTrust modal overlay
function hideOTModalOverlay() {
    const elementToHide = document.querySelector('#onetrust-consent-sdk .onetrust-pc-dark-filter');
    if (elementToHide) {
        // add delay for smoother animation
        setTimeout(() => {
            // @ts-expect-error - elementToHide is not in type
            elementToHide.style.display = 'none';
        }, 500);
    }
}

// HIDE OneTrust modal overlay, which sometimes stay after click on close button
function otModalClickHandler(event: MouseEvent) {
    // check if click was on OneTrust modal close btn
    // @ts-expect-error - event.target is not in type
    if (event.target?.id === 'close-pc-btn-handler') {
        // Hide modal overlay
        hideOTModalOverlay();
    }
}

// removes OneTrust modal overlay after Esc button was pressed
function otModalEscBtnHandler(event: KeyboardEvent) {
    if (event.key === 'Escape' || event.key === 'Esc') {
        // Check for the Escape key

        const closeBtn = document.getElementById('close-pc-btn-handler');
        if (closeBtn) {
            // Check if the element is in the viewport
            const rect = closeBtn.getBoundingClientRect();
            const inViewport =
                rect.top < (window.innerHeight || document.documentElement.clientHeight) &&
                rect.left < (window.innerWidth || document.documentElement.clientWidth) &&
                rect.bottom > 0 &&
                rect.right > 0;

            if (inViewport) {
                // Hide modal overlay
                hideOTModalOverlay();
            }
        }
    }
}

onMounted(() => {
    // add event Listener on document, because we can't be sure that onetrust modals were already loaded onMounted
    document.addEventListener('click', otModalClickHandler);
    document.addEventListener('keydown', otModalEscBtnHandler);
});
onUnmounted(() => {
    document.removeEventListener('click', otModalClickHandler);
    document.removeEventListener('keydown', otModalEscBtnHandler);
});
</script>

<style scoped lang="postcss">
.ui-simple-rich-text {
    &:deep(.css-float-left) {
        @apply float-left pr-2.5;
    }
    &:deep(.css-hide-desktop) {
        @apply md:hidden;
    }
    &:deep(.css-float-right) {
        @apply float-right pl-2.5;
    }
    &:deep(.css-text-xs) {
        @apply text-xs;
    }
    &:deep(.css-text-sm) {
        @apply text-sm;
    }
    &:deep(.css-text-md) {
        @apply text-base;
    }
    &:deep(.css-text-lg) {
        @apply text-lg;
    }
    &:deep(.css-text-xl) {
        @apply text-xl;
    }
    &:deep(.css-text-2xl) {
        @apply text-2xl;
    }
    &:deep(.css-text-3xl) {
        @apply text-3xl;
    }
    &:deep(.css-text-4xl) {
        @apply text-4xl;
    }
    &.text-left-align {
        @apply text-left;
    }
    &.text-center-align {
        @apply text-center;
    }
    &.text-right-align {
        @apply text-right;
    }
    &:deep(ul) {
        &:has(.css-list-checkmarks) {
            @apply list-none;
        }
        li {
            span:first-child.css-list-checkmarks:after {
                @apply content-["\2714"] block absolute -left-5 -top-1 text-base;
                font-family: 'Font Awesome 6 Duotone';
            }

            span:first-child.css-list-checkmarks {
                @apply relative pl-2;
            }
        }
    }

    :deep(.richtext-checkmark-list) {
        font-size: var(--checkmark-list-font-size);
        @apply ml-0 list-none;
        svg.checkbox-circle-line {
            height: var(--checkmark-size);
            margin-right: calc(var(--checkmark-size) / 2);
        }
        li {
            @apply mb-2.5 flex flex-nowrap items-start;
        }
    }
}
</style>
