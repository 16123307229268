<template>
    <UiSimpleImage v-editable="blok" class="sps-simple-image" :blok="blok" />
</template>

<script setup lang="ts">
import type { ISimpleImage } from '~ui/types';

defineProps<{ blok: ISimpleImage }>();
</script>
<style scoped lang="postcss">
.sps-simple-image {
    :deep(.bottom) {
        @apply bg-[#f5a12a];
        p {
            @apply text-white;
        }
        i:after {
            @apply bg-[#f5a12a];
        }
    }
}
</style>
