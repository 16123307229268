<template>
    <section v-if="blok" v-editable="blok" class="sbHero" :class="blok.CustomClass">
        <div class="hero-container lg:container">
            <div class="titles w-full px-4">
                <template v-for="title in blok.Titles" :key="title._uid">
                    <div v-if="selectedTab === title?.Type || blok.Titles.length === 1">
                        <h1>{{ getUtmTerm(title.Title) }}</h1>
                        <h2 v-html="title.Subtitle"></h2>
                        <a
                            v-if="title.link_title"
                            class="block text-white mt-4"
                            href="#"
                            @click="openVideoModal(title.video_url.url, title.video_control, title.autoplay)"
                        >
                            <NuxtIcon :name="`ri:${title.link_icon}`" class="mr-2" /> // TODO: Update Storyblok to use
                            Remix Icons
                            {{ title.link_title }}
                        </a>
                    </div>
                </template>
            </div>
            <div class="content-wrapper lg:flex w-full">
                <div v-if="blok.Searchbox && blok.Searchbox[0]" class="searchbox-wrapper lg:max-w-[60%]">
                    <Component
                        :is="searchbox.component"
                        v-for="searchbox in blok.Searchbox"
                        :key="searchbox._uid"
                        :blok="searchbox"
                        :titles="blok.Titles"
                    />
                </div>
                <div class="SPS-review-data">
                    <div class="review-rating">
                        <span class="text-secondary text-[56px] lg:text-7xl font-bold">96%</span>
                        <span class="text-secondary text-sm lg:text-xl font-bold w-[100px] lg:w-[175px] flex mb-1">
                            <NuxtIcon name="ri:star-fill" class="flex-1 h-6" />
                            <NuxtIcon name="ri:star-fill" class="flex-1 h-6" />
                            <NuxtIcon name="ri:star-fill" class="flex-1 h-6" />
                            <NuxtIcon name="ri:star-fill" class="flex-1 h-6" />
                            <NuxtIcon name="ri:star-fill" class="flex-1 h-6" />
                        </span>
                        <span>Review Rating</span>
                    </div>
                    <div class="review-total">
                        <span>Over</span>
                        <span class="text-secondary text-5xl lg:text-6xl font-bold">6,735,809</span>
                        <span>Customers</span>
                    </div>
                </div>
            </div>
        </div>
        <Modal :is-open="showVideoModal" @modal-closed="onCloseModal()">
            <template v-if="video.link" #content>
                <iframe
                    :src="`${video.link}&controls=${Number(video.control)}&autoplay=${Number(video.autoplay)}`"
                    title="YouTube video player"
                    frameborder="0"
                    :allow="`accelerometer; ${video.autoplay ? 'autoplay' : ''}; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share`"
                    allowfullscreen
                ></iframe>
            </template>
        </Modal>
    </section>
</template>

<script setup lang="ts">
import { useSearchStore } from '@/../_base/store/search';
import Modal from '../../../packages/ui/components/modals/Modal.vue';

const route: any = useRoute();
const getUtmTerm = (title) => {
    return route.query.utm_term ?? title;
};

const showVideoModal = ref(false);
const video = ref({
    link: '',
    control: false,
    autoplay: false,
});

const searchStore = useSearchStore();

const props: any = defineProps({ blok: { type: Object } });
const selectedTab = useState('activeTab', () => {
    if (props.blok.Searchbox?.[0]?.DefaultTab && props.blok.Searchbox?.[0]?.DefaultTab != 'None') {
        return props.blok.Searchbox?.[0].DefaultTab;
    }

    return props.blok.Searchbox?.[0]?.Tabs?.[0]?.Type;
});

if (props.blok.Searchbox?.[0]) {
    searchStore.updateActiveTab(selectedTab.value);
}

watch(searchStore, (store) => {
    if (store.activeTab != selectedTab?.value) {
        selectedTab.value = searchStore.activeTab;
    }
});

const openVideoModal = (videoLink: string, videoControl: boolean, autoplay: boolean) => {
    showVideoModal.value = true;
    video.value.link = videoLink;
    video.value.control = videoControl;
    video.value.autoplay = autoplay;
};

const onCloseModal = () => {
    showVideoModal.value = false;
    video.value.link = '';
};
</script>

<style lang="postcss" scoped>
.sbHero {
    @apply relative md:py-16 flex items-center justify-center bg-center bg-cover bg-gray-400 bg-[#1E86C2];

    .bg-overlay {
        @apply absolute top-0 right-0 bottom-0 left-0 opacity-50;
    }

    .hero-image {
        @apply absolute top-0 bottom-0 left-0 right-0 w-full h-full object-cover z-[0];
    }

    .hero-container {
        @apply relative z-[1] flex flex-col items-center justify-center mx-auto min-h-[300px] w-full;

        .titles {
            @apply text-center;
            h1,
            h2 {
                @apply text-gray-50;
            }
            h1 {
                @apply mb-2 text-4xl font-semibold;
            }
            h2 {
                @apply text-3xl font-normal;
            }
            a {
                @apply font-light;
                &:hover {
                    @apply underline;
                }
            }

            @media (max-width: 767px) {
                @apply text-left py-4;
                h1 {
                    @apply !text-2xl mb-0;
                }
                h2 {
                    @apply !text-base;
                }
            }
        }

        .searchbox-wrapper {
            @apply md:mt-12 w-full max-md:px-4;
        }
    }

    iframe {
        @apply w-[100vw] md:w-[90vw] max-w-[950px] h-[calc(100vw_*_0.5625)] max-h-[600px];
    }

    .SPS-review-data {
        @apply flex max-md:flex-col lg:flex-col justify-center items-center lg:w-[40%] text-white lg:text-2xl py-2 md:py-8;
        @apply md:gap-x-4;

        .review-rating {
            @apply flex flex-col items-center lg:mb-6 flex-1 flex-none;
        }

        .review-total {
            @apply flex flex-col items-center flex-1 flex-none;
        }
    }

    &:has(.searchbox-container.horizontal),
    &.horizontal-searchbox {
        .searchbox-wrapper,
        .SPS-review-data {
            @apply max-w-full w-full;
        }
        .content-wrapper {
            @apply lg:block;
        }
        .SPS-review-data {
            @apply flex-col md:flex-row justify-center;
            .review-rating {
                @apply order-2 mb-0 px-8;
            }
            .review-total {
                @apply order-1 flex-none px-8;
            }
        }
    }
}
</style>
