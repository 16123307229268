<template>
    <Modal animation-name="up" class="subscribe-modal" :is-open="modalStore.Subscribe" @modal-closed="handleCloseModal">
        <template #content>
            <div class="modal-dialog">
                <div class="modal-content">
                    <div class="left">
                        <h3>Get up to</h3>
                        <h2>{{ blok.discount }}% OFF</h2>
                    </div>
                    <div class="main">
                        <div class="image">
                            <NuxtImg
                                v-if="blok.image?.filename"
                                :src="blok.image?.filename"
                                :alt="blok.image?.alt"
                                provider="storyblok"
                                format="webp"
                                densities="x1 x2"
                                loading="lazy"
                            />
                        </div>

                        <p>{{ blok.message }}</p>

                        <form @submit.prevent="submitForm">
                            <div class="input-group">
                                <input id="email" v-model="formData.email" placeholder="Email address" type="email" />
                            </div>
                            <div class="button-group">
                                <button type="submit" name="Submit">Join Now</button>
                                <div v-if="formState.success" class="success">
                                    Thank you, you’re now able to use discount code: <span>COMPARE</span>
                                </div>
                                <button type="button" class="close" @click.prevent="handleCloseModal">
                                    <span v-if="!formState.success">NO THANKS</span>
                                    <span v-else>CLOSE</span>
                                </button>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </template>
    </Modal>
</template>

<script setup lang="ts">
import Modal from './Modal.vue';
import { useModalStore } from '~/../_base/store/modal';

const modalStore: any = useModalStore();

const { blok } = defineProps<{ blok: any; formState: any }>();

const emit = defineEmits<{
    (e: 'submit', formData: any): void;
    (e: 'reset'): void;
}>();

const formData: any = ref<any>({
    email: '',
});

const eraseFormData = () => {
    formData.value.email = '';
};

const handleCloseModal = () => {
    modalStore.toggleModal('Subscribe', false);
    eraseFormData();
};

const submitForm = async () => {
    emit('submit', formData.value);
};
</script>

<style lang="postcss">
.subscribe-modal {
    .background {
        @apply bg-gray-900/30;
    }
    .margin-content {
        @apply px-8 shadow-none;
    }
    .modal-dialog {
        @apply mx-auto;
    }
    .modal-wrapper {
        @apply max-w-[700px] md:w-auto w-full md:h-auto mt-32;

        .modal-content {
            @apply flex flex-row md:h-auto p-0 w-full md:w-auto bg-transparent;

            .left {
                @apply flex-[0] hidden md:flex flex-col justify-center items-center bg-primary text-center text-white px-8;

                h2 {
                    @apply text-5xl font-bold;
                }

                h3 {
                    @apply text-2xl font-semibold whitespace-nowrap;
                }
            }

            .main {
                @apply pt-4 pb-2 px-16 flex-1 flex flex-col justify-between items-center bg-white text-center;

                .image {
                    @apply mt-4 mb-8;
                }

                p {
                    @apply text-base text-gray-500;
                }

                form {
                    @apply w-full mt-8;
                }

                .input-group {
                    input {
                        @apply w-full px-2 py-2 border border-gray-300 rounded;
                        &::placeholder {
                            @apply text-gray-500;
                        }
                    }
                }

                .success {
                    @apply mt-2 text-green-700;
                    span {
                        @apply font-semibold;
                    }
                }

                .button-group {
                    @apply flex flex-col mt-2;
                    button {
                        @apply mt-2 py-2 px-4 rounded text-gray-500 text-xs;
                        &[type='submit'] {
                            @apply bg-secondary/80 text-white text-base transition-all;
                            &:hover {
                                @apply bg-secondary/100;
                            }
                        }
                    }
                }
            }
        }
    }
}
</style>
