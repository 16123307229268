<template>
    <UiHeaderBase id="uiHeader" :blok="blok" />
</template>

<script lang="ts" setup>
defineProps<{ blok: any }>();
</script>

<style lang="postcss">
#uiHeader {
    @apply bg-primary text-white fixed z-50 top-0 inset-x-0 uppercase;

    .container {
        @apply max-xl:relative max-xl:px-[15px] max-xl:py-[21px] h-[80px] xl:max-w-screen-2xl;
    }
    a {
        @apply text-white;
    }

    .dropdown-mobile-menu {
        @apply bg-primary;
        &.active {
            @apply max-xl:block max-xl:overflow-y-auto max-xl:max-h-[calc(100vh_-_80px)];
            @apply top-20;
            @supports (height: 100dvh) {
                & {
                    max-height: calc(100dvh - 80px);
                }
            }
        }

        li:not(.rich-text-menu-item) {
            a,
            .dropdown-item-content {
                @apply py-6 text-center;

                &:hover {
                    @apply bg-[#044e78];
                }
            }

            .dropdown-item-content {
                &.active {
                    @apply pb-0;
                }
                > span {
                    @apply pr-2;
                }

                .iconify {
                    @apply h-6 overflow-visible w-6 -rotate-90;
                    vertical-align: -0.35em;

                    &.active {
                        @apply rotate-0;
                    }
                }

                .nav-dropdown {
                    @apply mt-5 pt-0;
                }
            }
        }

        .mobile-dropdown-menu-wrapper {
            @apply top-[79px];
        }

        .nav-dropdown {
            @apply block shadow-none max-h-[none];
            position: initial;

            .nav-dropdown-item {
                a {
                    @apply block text-center;
                }
            }
        }
    }

    .navigation-button {
        @apply text-[#efa842] w-12 h-12 rounded-[50%] border-[#efa842] border-solid border-2 max-xl:flex flex-wrap items-center justify-center relative;
        .iconify {
            @apply h-[20px] pointer-events-none;
        }

        .close-mobile-button,
        .open-mobile-button {
            @apply max-h-7;
        }
    }

    .rich-text-menu-item {
        :deep(.simple-richtext) {
            p {
                @apply text-base;
            }
            span {
                @apply text-center w-full block leading-[15px];

                &.text {
                    @apply text-xs leading-[15px] block mt-0 mb-[3px] mx-0;
                }

                &.title {
                    @apply text-[25px] leading-[27px] uppercase font-bold;
                }
            }
        }
        a {
            @apply block xl:inline-flex pt-2.5 pb-2.5 px-[15px] h-full;
            span {
                @apply text-center w-full block text-xs leading-[15px];

                &.text {
                    @apply text-xs leading-[15px] block mt-0 mb-[3px] mx-0;
                }

                &.title {
                    @apply text-[25px] leading-[27px] uppercase font-bold;
                }
            }
        }
    }

    .nav-row {
        @apply text-base leading-5 uppercase m-0 xl:flex max-xl:hidden;
    }

    .nav-row-item {
        @apply relative h-full px-2;
        &:not(.rich-text-menu-item):hover {
            background: #044e78;
        }

        &.rich-text-menu-item {
            @apply xl:pl-4 xl:pr-0;
        }

        span {
            @apply xl:px-2;
        }

        .iconify {
            @apply relative -top-[3px] h-[16px];
        }

        .dropdown-item-content {
            @apply h-full flex items-center;
            &.active {
                > .iconify {
                    @apply rotate-180;
                }
            }
        }
    }

    .nav-dropdown {
        @apply text-white bg-primary shadow-lg top-[80px] min-w-[auto] pb-6 pt-[20px] max-h-[80vh] overflow-y-auto;

        &.right {
            @apply left-auto right-0;
        }

        &.left {
            @apply left-0 right-auto;
        }
        .nav-dropdown-item,
        .nav-row-item {
            a:hover {
                @apply bg-secondary;
            }

            &.active > a {
                @apply bg-secondary;
            }

            a {
                @apply flex w-full relative items-center justify-between px-2 leading-8 transition-[background-color] duration-[0.3s] ease-[ease];

                .iconify {
                    @apply pl-10;
                }
            }
        }

        .nav-dropdown-item:hover {
            @apply bg-secondary;
        }

        &.inner {
            @apply top-[0] left-full items-baseline cursor-auto bg-[#3b3f43] min-w-full min-h-full block pt-0 grid-flow-col pb-4;

            a {
                @apply transition-[background-color] duration-[0.3s] ease-[ease];
            }
            a:hover {
                @apply bg-[#1d9ee0];
            }
            .top-link a {
                @apply pt-4;
            }
        }
    }

    .nav .nav-row .nav-row-item a,
    nav .nav-row .nav-row-item span {
        @apply text-white;
    }
}
</style>
