<template>
    <SbFooter :blok="blok" />
</template>

<script setup>
defineProps({ blok: Object });
</script>

<style lang="postcss">
#sbFooter {
    @apply text-white text-center pt-8 bg-[#004c76] overflow-x-hidden;

    .container {
        > *:not(.copyright) {
            @apply max-lg:px-8;
        }
    }

    .footer-container {
        @apply lg:flex flex-wrap max-lg:max-w-full;
        .overview {
            @apply lg:flex-[45%] lg:pl-8 lg:pr-32;

            .rich-text {
                @apply text-left;
                p {
                    @apply my-4;
                }
            }
        }
        .links {
            @apply max-md:pb-4;
            @apply md:flex flex-wrap gap-y-4 gap-x-0;
            @apply lg:flex-[55%];
            @apply lg:grid grid-cols-[3fr_2fr] items-start lg:gap-0 w-full;

            grid-template-areas:
                'left right-top'
                'left right-bottom';

            .section-label {
                @apply block text-lg leading-[22px] font-bold uppercase block text-[#f6ab2f] mt-0 mb-[13px] mx-0;
            }

            .links-lists-container {
                @apply md:basis-full md:gap-x-4;
                @apply text-start flex-wrap max-md:block;
                grid-area: left;

                .section-label {
                    @apply flex-[0_0_100%] mb-0;
                }

                .links-item a {
                    @apply transition-all duration-[0.3s] ease-[ease];
                    &:hover {
                        @apply text-[#f6ab2f];
                    }
                }
            }

            .socials {
                @apply max-md:pt-4;
                @apply md:basis-6/12;
                @apply text-start lg:max-w-[155px] lg:px-2;
                grid-area: right-top;
                .socials-item {
                    @apply inline-block pl-0;

                    img {
                        @apply rounded-[50%] transition-all duration-[0.3s] ease-[ease];

                        &:hover {
                            @apply border-[#f6ab2f];
                            background: #f6ab2f;
                        }
                    }
                }
            }
            .icon-items {
                @apply md:basis-6/12;
                @apply text-start lg:max-w-[155px] lg:px-2;
                grid-area: right-bottom;

                .icon-items-container {
                    @apply flex flex-wrap;
                    .icon-item {
                        @apply lg:flex-[50%] pr-4 pb-2;
                    }
                }
            }
        }
        .copyright {
            @apply flex-[0_0_100%] inset-x-0 relative mt-10 md:mt-11;
            &:before {
                @apply content-[''] block absolute bg-secondary bottom-0 h-full w-[100vw] ml-[-50vw] left-2/4;
            }
        }
    }

    .footer-accordions {
        @apply hidden;
    }

    .links {
        .links-lists-container {
            .links-col {
                @apply flex-1;
            }
        }
        .socials {
            a {
                @apply px-4;
                .socials-icon-wrap {
                    @apply mr-2;
                }
            }
        }
    }
    .copyright-content {
        @apply py-4 px-8 bg-secondary text-black relative z-[1] text-primary;
    }
}
</style>
