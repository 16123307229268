<template>
    <UiPartners :blok="blok" />
</template>

<script setup lang="ts">
import type { IPartners } from '~ui/types/components/Partners';

defineProps<{ blok: IPartners }>();
</script>

<style lang="postcss" scoped></style>
