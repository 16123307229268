<template>
    <UiSimpleRichtext :blok="blok" />
</template>

<script lang="ts" setup>
import type { ISimpleRichtextConfig } from '@nuxt-monorepo/ui/types/components/SimpleRichtext';

defineProps<{ blok: ISimpleRichtextConfig }>();
</script>

<style scoped lang="postcss">
.rich-text {
    :deep(p) {
        @apply font-normal text-body lg:text-lg text-base leading-[25px] mt-0 mb-[var(--paragraph-bottom-indent)];
        b {
            @apply lg:text-[22px] text-[20px] leading-[30px] font-semibold text-primary mt-0 mb-[-14px];
        }
    }
    :deep(h2) {
        @apply lg:text-[30px] lg:leading-[40px] md:text-[25px] text-[22px] leading-[28px];
    }
    :deep(a) {
        &:hover {
            @apply text-[#0056b3];
        }
    }
    :deep(h3) {
        @apply min-h-[57px] mt-6 text-xl font-bold text-[#055988] uppercase mb-[5px] mx-0 leading-[26px];
    }
    :deep(ol),
    :deep(ul) {
        li {
            p {
                @apply mb-0;
            }
        }
        @apply mb-4;
    }
}
</style>
