<template>
    <SPSHero id="sbHero" v-editable="blok" :blok="blok" />
</template>

<script setup>
defineProps({ blok: Object });
</script>

<style scoped lang="postcss">
#sbHero {
    :deep(.hero-container) {
        .titles {
            h1 {
                @apply text-[54px] font-bold uppercase;
            }

            h2 {
                @apply text-xl font-light;
            }
        }
        .bg-overlay {
            @apply bg-repeat bg-auto;
        }
        @media screen and (max-width: 768px) {
            .searchbox-steps {
                @apply absolute flex -bottom-2.5;
            }
        }
    }

    &.sps-business-hero {
        :deep(.hero-container) {
            .searchbox-wrapper {
                @apply max-w-full;
            }
            .SPS-review-data {
                @apply hidden;
            }
        }
    }
}
</style>
