<template>
    <UiButton id="SbButton" class="sb-button" :blok="blok" />
</template>

<script setup>
defineProps({ blok: Object });
</script>

<style lang="postcss" scoped>
.sb-button {
    @apply flex justify-center;
}
</style>
