<template>
    <div>
        <UiTextTitleIconList v-editable="blok" :blok="blok" />
    </div>
</template>

<script setup>
defineProps({ blok: Object });
</script>

<style lang="postcss" scoped>
:deep(.text-title-icon-list) {
    @apply text-[#343a40] text-sm sm:text-base gap-y-4 flex flex-col mb-8;
    h5.title {
        @apply text-left lg:text-[22px] text-[20px] leading-[30px] font-semibold text-primary mt-0;
    }
    .item {
        @apply grid grid-cols-6 py-4 sm:flex sm:py-2 gap-x-4;
        &:not(.mobile-stack) {
            .item-icon.large {
                @apply justify-center;
                img {
                    @apply max-md:w-auto;
                }
            }
        }
    }
    .item-body {
        @apply col-span-5 flex flex-col justify-center;
    }
    .item-icon {
        @apply md:self-start self-center flex items-center min-w-[62px];
        .icon-wrap {
            @apply h-full w-auto;
        }
        img {
            @apply block max-w-none h-full;
        }
        &.large {
            @apply h-[45px];
        }
    }
    .item-title {
        @apply text-left lg:text-[22px] text-[20px] font-semibold text-primary mt-0;
    }
    .item-text {
        @apply font-normal text-body text-left lg:text-lg text-base leading-[25px];
    }
}
</style>
