<template>
    <UiTextTitle class="sb-text-title" :blok="blok" />
</template>

<script setup lang="ts">
import type { ITextTitle } from '~ui/types/components/TextTitle';

defineProps<{ blok: ITextTitle }>();
</script>

<style scoped lang="postcss">
.sb-text-title.TextTitle {
    @apply px-0;
    :deep(.title) {
        @apply box-border relative lg:text-[35px] md:text-[30px] text-[22px] leading-[39px] font-bold text-primary text-center mt-0 mx-0 pt-0 px-0;
        white-space: initial;
        &.show-dividers {
            @apply pb-5;
            &:before {
                @apply content-[""] w-9 h-[3px] ml-[-18px] absolute bg-[color:var(--divider-color)] mr-0 my-0 left-2/4 bottom-px;
            }
            &:after {
                @apply content-[""] absolute bg-[color:var(--divider-color)] w-[164px] h-px ml-[-82px] mr-0 my-0 left-2/4 bottom-0.5;
            }
        }
    }
    :deep(.container) {
        @apply px-0;
    }
    :deep(p) {
        @apply pt-4;
    }
}
</style>
